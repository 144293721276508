// Generated by Framer (0b5b7f5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["z7cNqWBhW", "Cjb3QcOLg"];

const serializationHash = "framer-bm4mx"

const variantClassNames = {Cjb3QcOLg: "framer-v-1eoltw0", z7cNqWBhW: "framer-v-95qf7g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Close: "Cjb3QcOLg", Menu: "z7cNqWBhW"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "z7cNqWBhW", XKaY3MrGu: tap ?? props.XKaY3MrGu} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XKaY3MrGu, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "z7cNqWBhW", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapc2fruv = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (XKaY3MrGu) {const res = await XKaY3MrGu(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-95qf7g", className, classNames)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"z7cNqWBhW"} onTap={onTapc2fruv} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({Cjb3QcOLg: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-txdbph"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"TJbz0zxyj"} style={{backgroundColor: "var(--token-5ed1cc28-c060-4755-a750-863980cab513, rgb(230, 230, 230))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{Cjb3QcOLg: {rotate: -45}}}/><motion.div className={"framer-138p0te"} data-framer-name={"Mid"} layoutDependency={layoutDependency} layoutId={"sMssH9RD7"} style={{backgroundColor: "var(--token-5ed1cc28-c060-4755-a750-863980cab513, rgb(230, 230, 230))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, opacity: 1, rotate: 0}} variants={{Cjb3QcOLg: {opacity: 0, rotate: 45}}}/><motion.div className={"framer-1kef7wh"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"X8E7JOotj"} style={{backgroundColor: "var(--token-5ed1cc28-c060-4755-a750-863980cab513, rgb(230, 230, 230))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{Cjb3QcOLg: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bm4mx.framer-1p7b7gt, .framer-bm4mx .framer-1p7b7gt { display: block; }", ".framer-bm4mx.framer-95qf7g { cursor: pointer; height: 44px; overflow: hidden; position: relative; width: 44px; }", ".framer-bm4mx .framer-txdbph { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(63.636363636363654% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-bm4mx .framer-138p0te { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: 21px; width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-bm4mx .framer-1kef7wh { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(36.36363636363639% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-bm4mx.framer-v-1eoltw0.framer-95qf7g { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 44px); }", ".framer-bm4mx.framer-v-1eoltw0 .framer-txdbph, .framer-bm4mx.framer-v-1eoltw0 .framer-1kef7wh { top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Cjb3QcOLg":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XKaY3MrGu":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerqcDl3JpFM: React.ComponentType<Props> = withCSS(Component, css, "framer-bm4mx") as typeof Component;
export default FramerqcDl3JpFM;

FramerqcDl3JpFM.displayName = "Navigation/Menu Icon";

FramerqcDl3JpFM.defaultProps = {height: 44, width: 44};

addPropertyControls(FramerqcDl3JpFM, {variant: {options: ["z7cNqWBhW", "Cjb3QcOLg"], optionTitles: ["Menu", "Close"], title: "Variant", type: ControlType.Enum}, XKaY3MrGu: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerqcDl3JpFM, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})